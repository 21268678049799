import React from 'react'
import { graphql, Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Cards, Card } from '@accodeing/gatsby-theme-heimr'

import Layout from 'components/Layout'
import Checkmark from 'components/Checkmark'

const OmOss = ({ data }) => {
  const employees = data.allEmployeesYaml.edges.map((edge) => edge.node)

  return (
    <Layout
      seo={{
        title: 'Om oss',
        description:
          'Vi heter Helena och Adina. Vi jobbar digitalt och rikstäckande men sitter i Åkersberga och Stockholm.',
      }}
    >
      <oma-grid-row>
        <section className='section'>
          <h1 className='page__heading'>Vi är The Planner</h1>
          {employees.map((employee, index) => (
            <div className='employee' key={index}>
              <GatsbyImage
                image={employee.image.childImageSharp.gatsbyImageData}
                className='employee__picture'
                alt={employee.name}
              />
              <div>
                <h2 className='employee__title'>
                  {employee.name}
                  <Checkmark />
                </h2>
                <p className='section__text'>{employee.presentation}</p>
              </div>
            </div>
          ))}
        </section>
        <hr className='section-divider' />
        <section className='section'>
          <h2 className='section__sub-heading'>Saker vi gillar</h2>
          <Cards className='cards'>
            <Card className='card card--without-border'>
              <div className='card__icon'>
                <i className='fal fa-books'></i>
              </div>
              <h3 className='card__title'>Planering</h3>
              <p className='card__text'>
                Planering: Ordning och reda är kanske inte det alla förknippar
                med något kul, men det gör vi! Planering, punktlighet och
                lösningsorientering ligger oss varmt om hjärtat. Tack vare det
                känner våra kunder en trygghet att vi alltid finns där och att
                vi har koll.
              </p>
            </Card>
            <Card className='card card--without-border'>
              <div className='card__icon card__icon--black'>
                <i className='fas fa-coffee'></i>
              </div>
              <h3 className='card__title'>Fika</h3>
              <p className='card__text'>
                Vi älskar att fika - te, kaffe, choklad, kanske en pekannötspaj.
                Vi ses gärna över en fika och pratar om er verksamhet. Kontakta{' '}
                <Link to='/kontakt'>oss</Link> gärna för ett möte!
              </p>
            </Card>
            <Card className='card card--without-border'>
              <div className='card__icon'>
                <i className='fas fa-laptop'></i>
              </div>
              <h3 className='card__title'>Digitalisering</h3>
              <p className='card__text'>
                Vi jobbar digitalt för en hållbar framtid. Det gör oss
                tillgängliga, effektiva och snabba med svar på era frågor.
              </p>
            </Card>
          </Cards>
        </section>
      </oma-grid-row>
    </Layout>
  )
}

export default OmOss

export const query = graphql`
  {
    allEmployeesYaml {
      edges {
        node {
          name
          presentation
          image {
            childImageSharp {
              gatsbyImageData(
                width: 200
                height: 300
                quality: 80
                transformOptions: { grayscale: true }
                layout: FIXED
              )
            }
          }
        }
      }
    }
  }
`
